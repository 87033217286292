import React from 'react'

import './Footer.css'

export const Footer = () => {
  return (
    <div className='googl-footer'>
        <p>Googl Atayde Inc 2022</p>
    </div>
  )
}
