import { Link } from "react-router-dom";
const toTopHandler = () => {
  window.scrollTo(0, 0);
};
export const entries = [
  {
    link: "/Week-1",
    title: "Week 1",
    date: "Oct 3, 2022",
    content: (
      <>
        <p>
          The first week as an encorian must be one of the most difficult but at
          the same time exciting things I can write about. For me, this means
          the start of a new challenge, since I’m at the start of my
          professional career, so these few days have been like a roller coaster
          inside my mind.
        </p>
        <p>
          Tuesday was the most exciting day, we got to meet the people we were
          talking to through emails and text messages at the kickoff meeting.
          Although we didn’t do too much, we were told about how we would be
          working through the week, all the information about our accounts, and
          how to set up the devices we got sent. Last but not least one of the
          things I really enjoyed was meeting all my colleagues and hearing
          their reasons for entering Encora. At the end of the day, we got our
          first assignments, which consisted of two essays and one online
          assessment.
        </p>
        <p>
          The second day was the busiest one and the one I was expecting the
          most since the topics seemed really interesting to me. We started with
          the Safe Spaces talk, which let us know about the different
          departments where we are free to ask for help depending on the
          situation if we feel lost, or need something. This is one of the
          reasons I picked Encora, since after reading all the information about
          the company, it is noticeable that the thing they care about the most
          is the wellness of their workers. Then we got the Training Class,
          which was a small talk about the perks and benefits we have with our
          personal email.
        </p>
        <p>
          By the end of the day, I joined the Art of Feedback talk. In this talk
          I felt I became someone else at the of it. This is where I learned the
          most. The talk was about how important it is to always express what
          you think and what you feel with your coworkers and clients in the
          most polite way possible, so things can go smoothly and there are no
          misunderstandings. Moreover, I learnt the real meaning of empathy, and
          how to distinguish it from sympathy. I always struggle differentiating
          these two words since I am someone who is direct when I speak, and I
          always say everything I think is wrong, as well as knowing how to
          recognize when I’m in the wrong. This talk really helped me
          pinpointing how I can improve to better understand people and what
          they need.
        </p>
        <p>
          Thursday was the most important day as we discussed security issues
          and working environments at Encora. In the “do and not to do” class we
          discussed the most common ways people get scammed or get their
          information stolen and how to prevent it. The topics were:
        </p>
        <ul>
          <li>
            <b>Social Engineering:</b> Is the way people manage social change
            and regulate the way someone will behave in the future.
          </li>
          <li>
            <b>Malware:</b> Malicious software that can take control over your
            computer and know every move you make.
          </li>
          <li>
            <b>Ransomware:</b> Malicious software which will block all access to
            your computer until a fee is paid.
          </li>
          <li>
            <b>Phishing:</b> Scam using known trademarks to make you think you
            are being contacted by a well-known company{" "}
          </li>
          <li>
            <b>Spear Phishing:</b> Personalized phishing where the scammer
            already has sensitive information about you
          </li>
          <li>
            <b>Trojans/Macros:</b>
          </li>
        </ul>
        <p>
          At the end, we were instructed on the actions we must take when we are
          presented in a situation like these.
        </p>
        <p>
          Afterwards, we got to know the methodologies followed at Encora with
          the “Agile” class. The topics weren't broadly explained although we
          got to know that Agile methodologies are used, such as Scrum, Xtreme
          Programming, KanBan and ScrumBan.
        </p>
        <p>
          On Friday, we started with a big surprise. We got to use our English
          in the entire meeting called “What is Operational Communication
          anyway...”, I was really shy at first but as the meeting progressed, I
          felt really confident at answering some of the questions that were
          asked. I felt motivated to know that we have a space and people that
          will be focused on spend time with us so we can improve our English as
          better as we can, it is something I never heard of in other places so
          I’m looking forward to be asking for help every time I don’t feel
          confident enough with my skills. Then, we joined the orientation
          class, which basically was an explanation of how life at Encora is,
          the perks of working here and a small talk about the speaker’s
          experience at the company, which made me even more excited at being
          here.
        </p>
        <p>
          At the end of the day, it was the checkout for the first week. I
          appreciated the format of the presentation as I felt as if I was
          talking to a member of my family. They asked questions normally other
          people wouldn’t care to know, but I felt heard, felt at home. It was
          really fun to hear everyone’s experiences and fun things they did
          throughout the week so I’m really happy and enthusiastic about
          participating next week too.
        </p>
        <p>
          Last but not least, I completed the online training assessments on the
          weekend. Since they were the same topics we discussed throughout the
          week, this was an amazing opportunity to reinforce the knowledge I
          acquired.
        </p>
      </>
    ),
  },

  {
    link: "/Week-2",
    title: "Week 2",
    date: "Oct 10, 2022",
    content: (
      <>
        <p>
          My second week as an Encora Apprentice was a little bit more relaxed,
          although that doesn’t mean it was easy. We got to use a programming
          learning tool called HyperSkill. There, we got asked to complete a
          Java BackEnd Developer path to get used to this programming language.
          I already had experience with other programming languages such as
          Python, JavaScript and C++, so this has been a really nice refresher
          for me since I got the opportunity to re-learn concepts I forgot over
          the years, and got the opportunity to reassure knowledge that was not
          100% clear before.
        </p>
        <p>
          The platform is really easy to use as it is really intuitive. The way
          it works is by projects, so you can practice everything right after
          you learn it. All the summaries and explanations are clear, and what I
          liked the most is that you get to test the knowledge gained after
          every lecture in an enjoyable way.
        </p>
        <p>
          We were asked to work on a cinema project, which basically was divided
          into 5 sections:
        </p>
        <ul>
          <li>
            Learning how to print, and basic concepts about the programming
            language.
          </li>
          <li>Syntax.</li>
          <li>Arrays and Loops.</li>
          <li>
            Functional programming (which I learned are called methods in Java).
          </li>
          <li>Error handling.</li>
        </ul>
        <p>
          The project asked us to write a program which would let us construct
          our cinema by printing the available seats on the console using
          multidimensional arrays, pricing them according to their position, and
          letting the user pick their seats. At last, we were asked to handle
          every action that would make the program crash, like user made
          mistakes, or wrong calculations that would happen within our code.{" "}
        </p>
        <p>
          Aside from the HyperSkill platform, I attended a couple of meetings
          throughout the week. The first one was “Communication” which I could
          describe as a complement to the meet we had on the next day called
          “Client Introduction”. Both were very similar as they let us
          comprehend that letting people know what you are thinking, what you
          understand from them is the most important part as a developer. You
          always need to have clear all the requirements from your client, and
          not be shy to ask “silly” questions, as sometimes not asking and not
          talking on spot, would lead to a complete loss in the future. Both
          meetings gave us recommendations on how to broaden our communications
          capabilities, and how and who to ask if we encounter problems with
          someone.{" "}
        </p>
        <p>
          The second meeting was the continuing part of “Do and what not to do”
          talk. Here I could learn who to ask if sometime I have the need to ask
          for tools, licenses or software about security such as VPN’s,
          subscriptions to any webpage, etc. In addition, I met people that I
          could ask for help whenever I make a mistake or something that could
          harm the company.
        </p>
        <p>
          The last meeting of the week was “Opportunity Seagulls”. Here we were
          introduced to the marketing team, which gave us a broad talk about how
          we can contribute to the company by forwarding every potential client
          we meet in our daily life, work meetings, work travels and such to the
          marketing department.
        </p>
        <p>
          Last but not least, this week was a transformation time for me. I can
          start by talking about Kenny Werner’s talk at Google. I have always
          been a really shy person, I don’t really like to make mistakes, make
          people think I don’t know how to do things. This talk really hit me,
          hit my mind with all its power. This talk made me understand that
          there will always be people judging, trying to compete, trying to
          demonstrate how much they know, and that’s also alright, but the thing
          we want to focus on is not letting all these things make us lose our
          will to keep pushing forward.
        </p>
        <p>
          Programming is full of stressful paths that will always make us feel
          we don’t know anything, or at least, that’s how I’ve felt a lot of
          times. But to be honest, I never paid attention to the other side of
          the coin. This feeling is not something bad whatsoever, it is
          something we can learn from. The frustration you have when you don’t
          understand a topic, or when you don’t understand how to solve a
          problem within your code, it is a feeling that can have two outputs,
          walking away, or keep learning. Although every time I have picked to
          keep learning, I always focused on the bad things of it, having the
          fear of having that frustration again. But this talk has made me see
          the real meaning of everything I feel. There is no shame in having
          fear of failing, there is no shame in asking what you don’t know and
          there is nothing wrong in failing even one more time. The only thing
          that actually is a shame, is not trying at all.{" "}
        </p>
        <p>
          All the documents and videos had similar topics, focused on different
          disciplines, but in the end, it can be applied to every aspect of our
          life. As said in the inner game of tennis, “it’s not about the tennis,
          it’s not about the win or the loss, if we’re here to experience, then
          we are free”, same happens with programming. Most of us (I include
          myself), sometimes think this is a competition, and in some way, it
          is, although it’s not about winning or losing, you need to actually
          enjoy programming, knowing every aspect of it, and not just do it
          because you are required to.
        </p>
        <p>
          This week’s readings were focused on getting more confidence about
          myself, and I know that these readings have already made a lot for me.
          Even putting it into practice, the sleeping method has made a little
          bit of improvement. It has helped me not to wake up during the night,
          which is really usual for me. I don’t know if it’s a placebo effect,
          but everything that I learned and did this week has been a roller
          coaster for my mind, and my heart in a good way. And I think my life
          at Encora these months will be the same, which makes me really excited
          about the future, and how my future me will be.{" "}
        </p>
      </>
    ),
  },
  {
    link: "/Week-3",
    title: "Week 3",
    date: "Oct 17, 2022",
    content: (
      <>
        <p>
          This week I had the opportunity to participate in a UI Design workshop
          called “Design Sprint”, although a sprint is most of the time 5 days
          of work, we rushed on learning just the most important ideas of a UI
          design sprint in 2 hours.
        </p>
        <p>
          In the meeting, we formed groups of 7+ people which would be our
          design team. The idea was to emulate a real team with a real client
          that was in need for a communication app for their company. We used an
          online workspace tool to make notes and develop the prototype. The
          first step was to brainstorm about what we thought we needed to ask
          the client to gather all the information before we could start
          developing ideas and prototypes. The questions we would ask to the
          client were written by all the team in notes as found in Fig. 1. There
          wasn’t a limit on how many questions we could make, the more, the
          better.
        </p>
        <div className="blog-img">
          <img src="https://i.imgur.com/fZ6wPJA.png" alt="Brainstorm"></img>
        </div>
        <p className="entry-figure">Fig. 1. Brainstorm notes.</p>
        <p>
          After the brainstorm we would emulate a call with a client which was a
          member of another team. We asked him all the questions, and all the
          answers provided would serve us to develop ideas and more questions
          about the product our client would like to get. These new questions
          were called “How Might We”. They were crucial to pinpoint the most
          important features that were needed in our app, here we polished the
          details on how we were going to develop the prototype and let us know
          how to start sketching out ideas.
        </p>
        <p>
          Once we had all our information selected, it was time to start the
          prototype development. The online workspace let us select cards that
          were similar to a phone screen. (Fig. 2). Here we made 8 different
          screens for each part of our app, this part of the sprint was called
          Crazy 8. Although it was rough to do it in so little time at the end,
          we could make different designs that were further discussed with the
          client, and all the expectations were met.
        </p>
        <div className="blog-img">
          <img src="https://i.imgur.com/eH1qzm4.png" alt="Crazy 8"></img>
        </div>
        <p className="entry-figure">Fig. 2 Crazy 8 design.</p>
        <p>
          This week I learned a lot about UI design patterns and the most
          typical software to use when we need to work as a team, basically, the
          most important parts or “stages” of a design sprint are the following:
        </p>
        <ol>
          <li>
            The team will brainstorm ideas and propose all kind of questions
            which would help to solve the problem we are facing; these will be
            answered by the client.
          </li>
          <li>
            Each member of the team will select and sketch some of the winning
            ideas after the interview with the client, this will let us have
            potential solutions and perspectives on how to start working.
          </li>
          <li>
            A single winning sketch will be selected after a voting session.
            This selected idea needs to be critiqued one more time to polish it
            as close to perfection.
          </li>
          <li>
            Prototyping starts in this stage; the selected idea is transformed
            into a realistic prototype.
          </li>
          <li>
            End part of the sprint, here the prototype is reviewed and redone if
            needed.
          </li>
        </ol>
        <p>
          Aside from the design sprint, this week we got different coding
          challenges from Google Jam. Five of them were team challenges and we
          got to decide a single problem to solve it by our own. Through the
          week us, the Interns, made two calls to divide the challenges
          depending on everyone’s capabilities and everyone's programming
          languages. All these coding challenges will be further discussed in
          the next week entry, although this week I had a chance to practice
          data structures and algorithms while trying to solve all coding
          challenges on my own.
        </p>
        <p>
          Lastly, we were asked to start our own blog. We could use any webpage
          or tool we wanted, so I decided to build my own, which is the one
          where you are reading right now. 😁
        </p>
      </>
    ),
  },
  {
    link: "/Week-4",
    title: "Week 4",
    date: "Oct 24, 2022",
    content: (
      <>
        <p>
          This week was a really busy and tiring but satisfying experience in my
          opinion. Through the week, as I mentioned in my last entry, I worked
          on Google Jam challenges as a team.
        </p>
        <p>The challenges we had were:</p>
        <ul>
          <li>
            <a
              href="https://codingcompetitions.withgoogle.com/codejam/round/0000000000876ff1/0000000000a4621b"
              target="_blank"
              rel="noreferrer"
            >
              Punched Cards
            </a>
          </li>
          <li>
            <a
              href="https://codingcompetitions.withgoogle.com/codejam/round/0000000000876ff1/0000000000a4672b"
              target="_blank"
              rel="noreferrer"
            >
              3D Printing
            </a>
          </li>
          <li>
            <a
              href="https://codingcompetitions.withgoogle.com/codejam/round/0000000000876ff1/0000000000a46471"
              target="_blank"
              rel="noreferrer"
            >
              d1000000
            </a>
          </li>
          <li>
            <a
              href="https://codingcompetitions.withgoogle.com/codejam/round/0000000000876ff1/0000000000a45ef7"
              target="_blank"
              rel="noreferrer"
            >
              Chain Reactions
            </a>
          </li>
          <li>
            <a
              href="https://codingcompetitions.withgoogle.com/codejam/round/0000000000876ff1/0000000000a45fc0"
              target="_blank"
              rel="noreferrer"
            >
              Twisty Little Passages
            </a>
          </li>
        </ul>
        <p>
          All of them needed to be made on 4 different programming languages
          which were TypeScript, Kotlin, Dart and Python. To be honest I was
          kind of scared at first, since it would be my first time working with
          Kotlin and Dart. I didn’t have any experience on those two, but at
          least I knew they were similar to Java and I already had been
          practicing that language with the Hyperskill courses.
        </p>
        <p>
          As a team, we decided to pick any problems we wanted voluntarily in
          any programming language we felt confident in. As I have worked with
          Python the most, I started with the first three problems, which I
          could solve within 1 day. The three of them didn’t require any data
          structure but arrays and just for loops which didn’t involve any
          algorithm in particular, they were really straightforward, so after
          solving them we discussed them in another team call and we decided to
          translate the code to the other 3 programming languages.{" "}
        </p>
        <p>
          The problem came with the last 2 problems, Chain Reactions and Twisty
          Little Passages. They seemed really intimidating and it was hard to
          visualize what path to take at first, so we agreed on discussing in
          detail what we could do to solve it. After the meeting, I tried
          solving the fourth one with dynamic programming since it was the most
          discussed topic in the meeting, with no luck at all. I was really
          frustrated as there was no luck on passing all the tests provided on
          Google Jam, there were just a few of them correct. At last, we
          discussed these last 2 problems once again and two of my teammates
          were able to successfully explain how to solve them. I was really
          happy to work with them, since there was no judgement, no laughs and
          there were never “dumb” questions, everyone were supportive and we
          learned from each other a lot.
        </p>
        <p>
          Afterwards, with all the explanation and feedback we had, I
          volunteered to solve both problems on Dart, since I wanted to test my
          learning capabilities with a whole new syntax. Dart was really similar
          to C# and Java and the thing I struggled the most was type
          declarations, as you need to be specific and watch the types of all
          variables you declare.{" "}
        </p>
        <p>
          After one whole day of Dart syntax 101 I was able to solve both
          problems, the last one, was trickier since it was more like a
          statistics and probability problem rather than a programming one.{" "}
        </p>
        <p>
          As a team, we managed to solve all of these problems relatively not
          that slow, but later in the week we got 3 more coding challenges,
          which were:{" "}
        </p>
        <ul>
          <li>
            <a
              href="https://codingcompetitions.withgoogle.com/codejam/round/0000000000876ff1/0000000000a4621b"
              target="_blank"
              rel="noreferrer"
            >
              Double or One Thing
            </a>
          </li>
          <li>
            <a
              href="https://codingcompetitions.withgoogle.com/codejam/round/0000000000877ba5/0000000000aa8e9c"
              target="_blank"
              rel="noreferrer"
            >
              Equal Sum
            </a>
          </li>
          <li>
            <a
              href="https://codingcompetitions.withgoogle.com/codejam/round/0000000000877ba5/0000000000aa9280"
              target="_blank"
              rel="noreferrer"
            >
              Weightlifting
            </a>
          </li>
        </ul>
        <p>
          As we already had experience with the last ones, we did basically the
          same steps to solve them, everyone volunteered to solve each of them.
          I worked on the first and last problem. The first one didn’t require a
          specific algorithm to solve, it was straightforward like the ones I
          solved earlier in the week. I worked with Dart to solve it. But again,
          the problem started with the third one as no one could solve it on
          their own. A meeting was held and we discussed possible solutions, and
          after hours of discussion everyone agreed to start researching and
          seek a solution we could study and then implement it with our own
          code. All the solutions and explanations can be further explored here:
        </p>
        <div className="tec-log-buttons">
          <Link className="tec-log-btn" to="/TecLog-1" onClick={toTopHandler}>
            Individual Technical Log
          </Link>
          <br />
          <Link to="/TecLog-2" className="tec-log-btn" onClick={toTopHandler}>
            Group Technical Log
          </Link>
        </div>
        <p>
          Through the week I also spent an hour a day refreshing my knowledge on
          data structures and algorithms, which was really helpful to not get
          that stressed solving the real Google Jam problems. I re-visited the
          courses available on LeetCode.
        </p>
        <p>
          This week was really fulfilling for me, I got to know my teammates
          more and I appreciate so much that everyone is available to teach as
          well as they are not shy to ask if they don’t know something. I got
          the opportunity to learn a lot through teaching Python syntax, data
          structures and algorithms to some of my teammates who reached me after
          getting lost on some coding challenges. This is the first time I tried
          to teach someone else coding and now I know why people say that the
          best way to fully understand something is to teach people about that
          topic. But I did not only teach people, I had the opportunity to learn
          from my teammates, and again, I was really happy to know everyone was
          open and understanding when everyone did questions. I really felt in a
          safe and confident place.
        </p>
      </>
    ),
  },
  {
    link: "/Week-5",
    title: "Week 5",
    date: "Oct 31, 2022",
    content: (
      <>
        <p>
          This week at Encora was really exciting. Although we only got one
          individual project this week, it was really motivating for me since we
          were required to make a fully functional app; backend, frontend and
          UI/UX.{" "}
        </p>
        <p>
          I won’t go in deep with the full explanation since maybe this project
          will be part of the next Encorians joining the apprentice. Basically,
          we were required to build a To-Do app, but not just a simple one. The
          requirements in summary were:{" "}
        </p>
        <ul>
          <li>
            CRUD (Create, Read, Update, Delete) backend made with Spring-Boot (I
            was scared when I read that)
          </li>
          <li>React for the front End (Exciting!)</li>
          <li>
            Have a UI with fully functional controls to sort and filter the todo
            list, add todos to the list, and a small window which would display
            information about, average time to end todos, etc
          </li>
        </ul>
        <p>
          {" "}
          As I was really pumped to start the project, I didn’t read these full
          requirements at first and ended up building a whole app with a
          different technology for the backend. I did all the backend (without
          validations) in mongoDB, and I was lucky to notice the requirements
          before fully connecting the backend with the frontend. I lost a whole
          day but at least I was able to practice some skills.{" "}
        </p>
        <p>
          {" "}
          Since I already have built the frontend with react, and the backend
          with mongoDB, I thought refactoring the backend would be actually easy
          to refactor in Spring-Boot framework, but I was wrong. I have always
          respected Java because it is a confusing programming language if you
          are not used to class-based programming languages. I tried courses and
          videos at first to understand the framework without any luck, so I
          remembered what a programming teacher at my university told me:{" "}
          <span>
            Everything will always be in the documentation, your life is already
            solved if you know how to read documentation.
          </span>{" "}
        </p>
        <p>
          {" "}
          The documentation was confusing at first, but after making a lot of
          mistakes, I started to pick up the pace of searching. How to make
          requests, how to store in-memory data for testing purposes, how to
          make dynamic ID’s, everything was already in the documentation, it was
          just a puzzle you needed to solve to get to your goal. I was super
          happy to understand everything as I always thought documentation was
          boring, and to be honest, I always have learnt better visualizing
          examples on videos. I spent a whole day just reading documentation,
          but everything made sense at the end.{" "}
        </p>
        <p>
          {" "}
          After a couple days my app was functioning as I wanted, backend and
          frontend connected but there were a lot of bugs when making http
          requests, so I spent the whole weekend understanding how these work in
          Spring-Boot.{" "}
        </p>
        <p> Here is a little (ugly) preview of the app: </p>
        <div className="blog-img">
          <img src="https://i.imgur.com/js3AQLD.png" alt="todo app" />
        </div>
        <p className="entry-figure">Fig. 1. Todo app</p>
        <p>
          {" "}
          Although it is almost done, it is still missing validations for when
          someone makes/edit a to-do item and a pagination. Also, it is still
          missing a pretty UI/UX which is what I plan to do next week.{" "}
        </p>
        <p>
          {" "}
          This week has been rough but amazing. I've had the opportunity to
          introduce myself to a new backend framework, and the opportunity to
          practice some Java (which I always wanted to learn but avoided doing
          for some reason). Even if my app is still not ready, I have been
          enjoying the daily challenges I have been encountering every day and I
          am excited about what is to come.
        </p>
      </>
    ),
  },
  {
    link: "/Week-6",
    title: "Week 6",
    date: "Nov 7, 2022",
    content: (
      <>
        <p>
          {" "}
          This entry will be the shortest I’ve written until today, since most
          of the things I did were the same as last week. This week I learned
          almost everything you need to know about running a simple API
          (application programming interface) in spring. I already had most of
          the applications’ backend ready since I’ve been working with spring’s
          dependency JPA since last week.{" "}
        </p>
        <p>
          {" "}
          But I encountered a problem, we weren’t allowed to use dependencies or
          databases within this project, and JPA ended up being both! So, after
          discussing which dependencies we were allowed to use, it was decided
          that we needed to do everything from scratch. So, I did.{" "}
        </p>
        <p>
          {" "}
          I already have worked with class-based programming so there were not
          too many blockers I encountered other than reading documentation while
          I was making the backend. I spent the whole Sunday working on spring
          and I only reused what I had on my frontend already, so I finished
          everything satisfactorily.{" "}
        </p>
        <div className="blog-img">
          <img src="https://i.imgur.com/UkQrzr4.png" />
        </div>
        <p className="entry-figure">Fig. 1 - Backend structure</p>
        <p>
          {" "}
          What I have left is to implement the errors on my front end and
          implement a good-looking UI with a satisfactory UX on it (and I need
          to write the technical log, but we will discuss that later).{" "}
        </p>
        <p>
          {" "}
          Here is a peek at my ugly updated UI featuring the new module with
          averages to finish the to-dos and some pagination.{" "}
        </p>
        <div className="blog-img">
          <img src="https://i.imgur.com/yTemYnJ.png" />
        </div>
        <p className="entry-figure">Fig. 2 - Current state of my App</p>
      </>
    ),
  },
  {
    link: "/Week-7",
    title: "Week 7",
    date: "Nov 14, 2022",
    content: (
      <>
        <p>
          Finally I finished the individual project, the one I’ve been talking
          about since week 5 (todo list). It was really hard to put everything
          together since in programming, you need to always keep thinking in the
          future. What do I mean with this? It is that you need to cover all
          possible error situations where the person who is using your
          software/app might encounter. A misclick, a double click where they
          are not supposed to do so, a disconnection, etc. There are endless
          possibilities. So, this is what I did this week, tests, tests and more
          tests, and a little bit of designing.{" "}
        </p>
        <p>
          So I started testing all the end points with a tool called Postman.
          Every CRUD operation was tested in detail and after I was happy with
          all the results, I started testing out the endpoints directly in my
          app. After everything worked as I wanted, I started with the UX/UI
          process. I really wanted to do everything by myself so this time I did
          not use a framework such as Bootstrap or Bulma and I must say I
          enjoyed every aspect of this project since it let me practice and
          refresh some knowledge.
        </p>
        <p>
          I didn’t really encounter any blocker while I was doing my project but
          of course, it does not mean everything will be right so I expect to
          fix all what’s wrong about my project once I get some feedback. In the
          meantime, here is a small pic of my project. (Yes every todo item is a
          little post-it)
        </p>
        <div className="blog-img">
          <img src="https://i.imgur.com/b0MoqEe.png" />
        </div>
        <p className="entry-figure">Fig. 1 - Main page of my app.</p>
        <p>
          If you’re interested in a full description of my project I encourage
          you to read the technical log I wrote about it here:{" "}
        </p>
        <div className="tec-log-buttons">
          <Link className="tec-log-btn" to="/TecLog-3" onClick={toTopHandler}>
            Todo App Technical Log
          </Link>
          <br />
        </div>

        <p>
          Aside of the project I spent some time going through the courses on
          spring-boot in hyperskill, it has been an amazing tool for learning
          and of course, it has helped me a lot to practice before actually
          building my project.
        </p>
        <p>
          I’m really looking forward on the next projects and what is to come!
        </p>
      </>
    ),
  },
  {
    link: "/Week-8",
    title: "Week 8",
    date: "Nov 21, 2022",
    content: (
      <>
        <p>
          This week was the first time I could take my time to do things. I got
          sick last week so I’m still recovering from a hard food poisoning, but
          now I feel better and ready to keep my normal working schedule!{" "}
        </p>
        <p>
          So the start of the week I could focus on myself, I started meditating
          as a suggestion I remember I got in one of the emails we get from the
          staff. I never believed in meditating but at least I wanted to give it
          a try. As I am someone that is always thinking too much, trying to get
          things done as fast as possible, all of this has been not a good
          practice for my mental health. So I’ve been trying to do it for at
          least 20 min in the morning and I think it has helped me A LOT. It is
          quite hard to get used to do meditation, I get distracted easily but
          after some days practicing I can say I can do it a little bit more
          easily, and one thing I must say is that it has helped me to focus a
          little bit more on what I want to do through the day.{" "}
        </p>
        <p>
          Aside from trying this new routine this week I did some leetcoding and
          hyperskill to refresh Java concepts, it is really easy to forget
          everything about Java so I try to at least read some code every day to
          really get used to remembering the syntaxis. Meditating, reading, and
          planning my day has really improved the way I do things through the
          week. I’m someone that (I’m being honest, sorry) procrastinate when
          something is boring, but these new practices have made me gain more
          confidence on doing things better, and of course, optimizing my time.{" "}
        </p>
        <p>
          Changing topics, this week I got feedback from my Todo App, and it
          saddened me a little bit that it was not fully functional as I
          thought, but I think it is normal to me feeling this way, but this
          can’t last forever. I've learned a lot in my stay at the internship,
          and one thing that I learned here that always keeps resonating in my
          mind is that it is okay to feel strong emotions, to let them flow, but
          after understanding them and of course feeling them, you need to
          improve. So after knowing what were my weak points and leaving the
          sadness away I started working.
        </p>
        <p>
          {" "}
          Mostly this week was to plan everything I needed to fix, so I started
          with the backend which was the weakest part of my app. This was the
          first time I worked with Java on a real project, so I decided reading
          all the documentation I used to develop my app once again. After
          knowing my mistakes, I started working on my spring-boot classes as
          follows:{" "}
        </p>
        <ul>
          <li>Reviewing the validation of my Model</li>
          <li>Refactoring the functions in my controller</li>
          <li>Implementing a correct Repository</li>
          <li>Working with errors withing my Service</li>
        </ul>
        <p>
          Lastly I want to mention that having someone reviewing my mistakes, it
          helped me a lot and it was super easy for me to improve, or at least
          thinking that I’m improving is what makes me to not giving up, it
          pumps my strength and will to keep learning. Although this feedback is
          not a guide, it makes my improving path clearer so this week I’m happy
          to start giving the finishing touches to my project to finally deliver
          an amazing app and I will be starting the new assigment (I'm
          nervous-excited) which is: Module Lightning-Talks!, where I will be
          presenting a topic related to technology so be ready for next week's
          post!
        </p>
      </>
    ),
  },
  {
    link: "/Week-9",
    title: "Week 9",
    date: "Nov 28, 2022",
    content: (
      <>
        <p>
          Finally the big day has come... the day to deliver our final product
          of my TodoApp!. To be honest, it was a great experience for me, I know
          programming can be time consuming and stresful but after gettig some
          experience handling errors, nothing is impossible. And I think
          everyone, or at least myself, I learn way more with my mistakes...
        </p>
        <p>
          I did way too many mistakes while using spring, but thanks to that I
          already feel competent enought in knowig the structure of a basic app.
          I'm really happy I managed to make my app run and that I got to learn
          a lot just from documentation and some StackOverflow (haha).
        </p>
        <p>
          So, this week I focused on refactoring two classes from my spring app,
          which were the service and the controller so the distribution of my
          project was something like this:
        </p>
        <h3>Models</h3>
        <p>
          The model will contain all the parameters for each to do item, and it
          will handle the validations for each parameter.
        </p>
        <h3>Repositories</h3>
        <p>
          Here is where we would have our repository that would connect to our
          database and where we will transform all the stored data that will go
          to our service. This time we will be handling this temporal database
          with a Java Collection, ArrayList.
        </p>
        <h3>Services</h3>
        <p>
          This part will contain all the logic of our application, here is where
          we have all the functions that deliver the desired data from the
          database.
        </p>
        <h3>Controllers</h3>
        <p>
          This part will controll the HTTP layer of the application, this should
          not be passed to the service.
        </p>

        <p>
          If you have interest on what I'm talking about please check my
          technical log to know about my full project!
        </p>
        <div className="tec-log-buttons">
          <Link className="tec-log-btn" to="/TecLog-3" onClick={toTopHandler}>
            Todo App Technical Log
          </Link>
          <br />
        </div>
        <p>
          Aside from refactoring my app, I spent most of the week reading about
          unit testing, this is the first time I did it so it was important to
          me to understand everything before executing code. I can't explaing
          broadly here or this entry would be more like a technical log, but
          here I will list the resources I found the most valuable for me to
          understand unit testing. Altough they were really basic introductions,
          it was more than enought to start doing tests myself. Of course, there
          are better sources but these two made me understand the structure
          needed to start testing and some concepts that are kind of hard to
          understand if you have never do testings before.
        </p>
        <div className="tec-log-buttons">
          <a
            className="tec-log-btn"
            target="_blank"
            rel="noreferrer"
            href="https://reflectoring.io/spring-boot-test/"
          >
            Spring Testing
          </a>
          <br />
          <a
            className="tec-log-btn"
            target="_blank"
            rel="noreferrer"
            href="https://learning.oreilly.com/videos/react-the/9781801812603/9781801812603-video26_9/"
          >
            React Testing
          </a>
        </div>
        <p>
          Side note: the react testing source (O'reilly) is actually teached by
          Maximilian Schwarzmüller, someone I recommend if you want to start
          taking courses about programming.
        </p>
        <p>
          Last but not least, I am still grinding hyperskill, a litle bit slowly
          since I was busy with the project but this time I have more time to go
          all in with this amazing learning source
        </p>
      </>
    ),
  },
  {
    link: "/Week-10",
    title: "Week 10",
    date: "Dec 5, 2022",
    content: (
      <>
        <p>
          The first Lightning Talk is done! This week was full of new
          experiences and new emotions for me, although I already had
          presentations in English before, this was the first time to do it
          online and with people that I did not know before. I couldn’t think
          about anything else than the presentation itself.{" "}
        </p>
        <p>
          {" "}
          I’m not someone that likes to speak in public, but I am always capable
          of keeping a chill mindset and show a confident face whenever I’m
          presenting so this time was a smooth (but rough) first time for me.
        </p>
        <p>
          So my presentation was called “Testing a React app”, and I decided to
          do this topic since it was a completely new experience for me, as I
          stated in my last entry. Not only in react but in spring boot too, but
          I think I enjoyed the testing side of react a little bit more. You can
          check my full presentation in the following button:{" "}
        </p>
        <div className="tec-log-buttons">
          <a
            className="tec-log-btn"
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/presentation/d/e/2PACX-1vSroPJx5VeMRLLFQXjk_X2krHmAnUcfTAUkx5MwdxrtUlapiQheMaFclW1X26jtb8DjBWOpsGWssCjn/pub?start=false&loop=false&delayms=3000&slide=id.ge7f9c668d6_0_0"
          >
            Testing a React App
          </a>
        </div>
        <p>
          I was the second one to present, and even though it was a 10-minute
          presentation I managed to give out all the information I had planned,
          I even took less than I expected and practiced, but I managed to just
          keep speaking until the 10 minute mark passed.{" "}
        </p>
        <p>
          After presenting, we needed to share a document for us to take
          feedback from our public. It was hard to read some commentary from my
          peers, but I think after all, it’s all about keep improving every day,
          so with this experience what I could learn, and expect to fix in the
          future is:
        </p>
        <ul>
          <li>
            Talk a little bit slower, I really pushed a lot of information in
            just one presentation.{" "}
          </li>
          <li>Have a stronger start and ending. </li>
          <li>
            Controle my voice tones, most of the time it was quiet, and
            sometimes it was super loud.
          </li>
          <li>Show my hands more, try to interact with people through them.</li>
        </ul>
        <p>
          So overall, I was happy to catch so detailed feedback, it is something
          I appreciate so much and I don’t take it personal, as I said in my
          homepage, “You can expect from me to always be learning and improving
          everything I do”, but of course, as long as the feedback is
          constructive and not just a critic I can keep pushing myself to the
          limit.
        </p>
        <p>
          Aside from the presentation, I can say with confidence that my mood
          has been improving lately, I haven’t stopped meditating, even if it is
          just for 5 minutes a day, it has helped me so much to get to know
          myself better, and I also started exercising more lately, so overall
          my head has been way less messy than when I started this apprentice.
        </p>
        <p>
          Finally, I have been working on hyperskill all week long and I plan to
          do it through this week too, apart from preparing my next Lightning
          talk of course. (:
        </p>
      </>
    ),
  },
  {
    link: "/Week-11",
    title: "Week 11",
    date: "Dec 12, 2022",
    content: (
      <>
        <p>
          Alright, this week was my second lightning talk and I think I’m
          getting the hang of talking to strangers without doubting myself. Last
          entry I talked about me being someone who doesn't like talking in
          presentations and stuff, but I think after all, it’s all about being
          confident with the knowledge you already have.{" "}
        </p>
        <p>
          To be honest, I’m still scared about presenting, don't get me wrong,
          but I think I’m way better prepared than before, the words in my mind
          come super easily and I can see from my feedback (yes we’ve been
          asking for feedback from our listeners) that I have actually improved.
          In my first talk, my feedback was kind of terrible, because most
          people focused on me being super fast and not having a high tone
          voice, but this time I put all my effort on fixing it and it paid off!
          I mean, there is still too many things to improve and of course I can
          always get better, but I’m really happy people noticed I put a ton of
          effort.{" "}
        </p>
        <p>
          {" "}
          So, after cheering myself up (haha), I must talk about what was my
          lightning talk about… and here you have it:{" "}
        </p>
        <div className="tec-log-buttons">
          <a
            className="tec-log-btn"
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/presentation/d/e/2PACX-1vQdfeQwvSOXPRQMwRqpDIq-RhWIP8gTfLUNQ4WozvqE1VUlYbkZWW4mvuwUamgBs6NPRxb-938_02dt/pub?start=false&loop=false&delayms=3000"
          >
            Data Structure - Graph
          </a>
        </div>
        <p>
          I picked that topic because I hate-love this data structure, I can
          handle most of the most popular data structures, but when it comes to
          graphs it gets difficult for me, because I take a long time trying to
          decipher how to solve a problem with it, the idea and pseudocode is
          easy to understand, but when it comes to implement it… another story.
        </p>
        <p>
          This week was full of hyperskill and Java too. I did the{" "}
          <a
            href="https://hyperskill.org/projects/189"
            target="_blank"
            rel="noreferrer"
          >
            Cinema Room REST Service{" "}
          </a>
          which was way too longer than I expected, but can finally say that I’m
          not shy to use Java anymore!. With the Todo project I could grasp and
          understand how to build a REST app from scratch, but after taking the
          Cinema project I understood everything I couldn’ when doing the Todo
          app. With this I mean everything about Java syntax, I used a lot of
          stuff in the todo project that knew how it worked, but didn’t fully
          understand what was behind the scene… Access modifiers, annotations,
          beans, etc.
        </p>
        <p>
          So to end this entry I would like to write about something I
          re-learned this week and I’m sure I will be asked in most interviews.
          I've seen this question too many times just to ignore it, which is:
          what are the four pillars of Object Oriented Programming?
        </p>
        <p>
          This is also a self reminder to never forget what these four pillars
          are about since they are the base of object oriented programming.
        </p>
        <h3>Abstraction</h3>
        <p>
          To abstract something away means to hide away the implementation
          details inside something. So when you call the function you don't have
          to understand exactly what it is doing.
        </p>
        <h3>Encapsulation</h3>
        <p>
          Removing access to parts of your code and making things private is
          exactly what Encapsulation is all about (often times, people refer to
          it as data hiding).
        </p>
        <h3>Inheritance</h3>
        <p>
          Inheritance lets one object acquire the properties and methods of
          another object. Reusability is the main benefit here.
        </p>
        <h3>Polymorphism</h3>
        <p>
          Polymorphism simply means 'many forms'. This means that you can have
          one function or object that can be used in different ways.
        </p>

        <p>
          Well if you read everything until here thank you so so much and until
          the next one!
        </p>
      </>
    ),
  },
  {
    link: "/Week-12",
    title: "Week 12",
    date: "Dec 19, 2022",
    content: (
      <>
        <p>
          {" "}
          Lightning talks are over! Well, the last one was actually an ignite
          talk (5 minute talk) but anyways it felt the same as the last ones. Of
          course I was nervous but for some reason just before my talk I was
          able to stay focused all the talk and even got a question I could
          answer with no problems. Everything was clear. I feel I have gained
          trust on my english skills since most of the reviews were positive
          this and last time. In addition, through last week we had english
          talks, where we can share about whatever topic we wanted, and I feel
          truly confident on speaking whatever is in my mind
        </p>

        <p>
          {" "}
          So, I already talked about the ignite talk, but didn't tell what it
          was about. Here is it if you want to have a look!
        </p>
        <div className="tec-log-buttons">
          <a
            className="tec-log-btn"
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/presentation/d/e/2PACX-1vRP4xQWUoLi0rFHNWSHwS18QGaQ5tAndm-gZ3UdrtRrmJC4ZSnNenBGZRA2JfMNtWGKE1mp_dR847Xb/pub?start=false&loop=false&delayms=3000&slide=id.g1b46c607197_0_6"
          >
            Object Oriented Programming 101
          </a>
        </div>
        <p>
          Yes, a lot of people might think, why Object Oriented Programming?
          Most of us already know what it is, and that is exactly what I thought
          too, but the thing is, that most of us forget the basic concepts about
          it, and when it comes to interviews it can happen that we can get
          stuck at explaining what OOP is
        </p>

        <p>
          {" "}
          OOP is a recurrent question in interviews from what I can gather on
          the internet and my experience, so that's why I picked it as my Ignite
          talk. Hope my presentation can help someone studying the concepts of
          OOP as they are summarized over there!.{" "}
        </p>
        <p>
          {" "}
          Aside from the talk, this week was full of hyperskill and full of
          googling stuff I couldn't understand from the mentioned page. Don't
          get me wrong, about the information in hyperskill, I think it is fit
          for the projects they offer, but sometimes it feels like they actually
          want us to research topics, as in some stages during the project
          itself, there are challenges that can't be completed without certain
          methods built in the programming language we are working (Java) or
          without algorithms or classes that are not explained during the
          “learning” stage.{" "}
        </p>
        <p>
          {" "}
          Anyways, reading every lecture through the project has helped me and
          it actually saved me during my presentation this week!. As I said,
          someone asked a question during my presentation and I wouldn't have
          been able to answer it without the help of hyperskill. I instantly
          remembered the lecture where that question was answered clearly, so,
          nice!
        </p>
        <p>
          {" "}
          Returning into the project, the{" "}
          <a
            href="https://hyperskill.org/projects/189"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Cinema Room REST Service{" "}
          </a>{" "}
          one was completed already earlier in the week and I can say that it
          was a project that helped me to fully understand how spring worked and
          how I can manage my project as it provides “boiler plating” and best
          practices for every MVC Spring project.
        </p>
        <p>
          {" "}
          So this last week has been mostly focused on the second project{" "}
          <a
            href="https://hyperskill.org/projects/130?track=1"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Code Sharing Platform{" "}
          </a>{" "}
          which to be fair, it has been tough as it's integrating API managing
          and web boiler plating to show a full and working app. Every stage of
          the project has been feeling as if it was an actual separated project,
          but every time one stage is completed, I feel more competent with
          Spring.
        </p>

        <p>
          {" "}
          So yeah, mostly these weeks have been all about studying and
          researching, and I'm happy that I haven't encountered any blockers
          yet, all the knowledge is already out there, we just need to know how
          to find it.{" "}
        </p>
      </>
    ),
  },
  {
    link: "/Week-13",
    title: "Week 13",
    date: "Dec 27, 2022",
    content: (
      <>
        <p>
          Reading, reading, more reading and practice!. I know this can be taken
          both ways, boring or exciting, but this time I will go with exciting!
        </p>
        <p>
          This week has been like the first ones, a total roller coaster… mixing
          work with festive days can be tough, but I survived both and I’m happy
          I pulled it so smoothly. Everyday was full of practice and full of
          reading documentation, everyday was Java and Spring Boot day. And, as
          I said in my last entry, I focused on the new project which was
          basically a code sharing API with a small frontend for people to
          interact with the code.
        </p>
        <div className="tec-log-buttons">
          <a
            className="tec-log-btn"
            target="_blank"
            rel="noreferrer"
            href="https://hyperskill.org/projects/130?track=1"
          >
            Code Sharing Platform{" "}
          </a>
        </div>
        <p>
          To be fair, there’s not too much I can write about it, I can only say
          that I can now understand the true power of Java! Practicing with
          classes through a REST API, has taught me something I respect a lot,
          and is always in my mind, which is: ORDER.
        </p>
        <p>
          Through these months working with Java was something completely new
          for me, and I’m not going to say it is an easy language, but as I
          stated above I can see why is a language so respected and used by
          enterprises, and it is because you can wrap up everything with
          objects, for everyone to easily understand how your app works. Models
          are a really powerful tool, and it’s libraries full of them are
          something that I have been appreciating so much as it makes
          development quicker and well structured.
        </p>
        <p>
          Before closing this post, I would like to say Happy Holidays to
          everyone!, I hope you all had a great time with your loved ones and of
          course...
        </p>
        <div className="new-year-container">
          <p className="new-year">HAPPY NEW YEAR!</p>
        </div>
        <p>
          I wish everyone a year full of self development, full of improvement
          but most importantly, a year full of good health and love! I hope you
          all can keep achieving your dreams and that every day of your life
          will be an exciting adventure.
        </p>
      </>
    ),
  },
  {
    link: "/Week-14",
    title: "Week 14",
    date: "Jan 2, 2023",
    content: (
      <>
        <div className="new-year-container">
          <p className="new-year">HAPPY NEW YEAR!</p>
        </div>
        <p>
          New year, new me!. This year there has been way too much stuff I
          learned, so many people I’ve met and of course, there has been this
          journey I’ve been enrolled in for 3 months. Even though so many events
          have happened, there is always space for improvement, and this year I
          want to push everything to the limit.
        </p>
        <p>
          I’m not going to lie, 2022 was a life changing year for me, there has
          been tough situations in my life that has made me take super hard
          decisions, and that has made me go through rough days… that’s why I
          hope, and I’m sure I want to make the best improvement I can so far in
          my own person, and I want to keep surpassing everything I do from now
          on. It’s not that I did not do the same all my life, but as I said,
          everything that happened to me in 2022 has made me open my eyes, wider
          than ever before and I am hungry for succes!
        </p>
        <p>
          Alright, after writing a little bit about me, I would like to talk
          about this new project we have this 2023. Finally I am working on a
          project that may be used in production.
        </p>
        <p>
          Broadly speaking about it, I will work in a fullstack app that will
          actually be deployed. It is a team project, so the tech stack
          discussed until now has been Java SpringBoot for backend, React for
          our frontend and PostgreSQL for our database. Using Java makes me
          really excited, as it is the top technologie used in big industries.
          There were other options such as node and django, but we all decided
          it would be best to practice what we’ve been learning through the last
          months.
        </p>
        <p>
          So far this week was full of meetings, the kick-off for the project
          and meetings with our team to discuss our workflow, roadmap and design
          of our app, as well as the already mentioned tech stack. I am
          impatient about what next week has to offer as we already will be
          working on the actual app (coding!) and something I must say is that
          my team and I could really connect instantly with everything we talked
          about, so I will do my best to deliver a quality product as a team!.
        </p>
      </>
    ),
  },
  {
    link: "/Week-20",
    title: "Week 20: Contributing to Open Source Software",
    date: "Feb 20, 2023",
    content: (
      <>
        <p>
          It has been some time since I wrote my last entry... Between working
          on larger projects and studying, my schedule has been packed.
          Nonetheless, I want to share what I have been working over the past
          week. I've been researching and going deeper into a topic I'm already
          familiar with: Open Source. I'm excited to share with you how you can
          start getting involved in this crucial subject that's essential to
          every programmer in the world. But before we jump into that, let's
          begin by defining what Open Source is and why it's so important.
        </p>
        <h3>What is open source?</h3>
        <p>
          Open source refers to software whose source code is freely available
          to anyone who wishes to view or modify it. This collaborative approach
          to software development has given rise to a global community of open
          source developers who work together to create and maintain software
          projects that are often available at little or no cost to the end
          user.
        </p>
        <div className="blog-img">
          <img
            className="oss-logo"
            src="https://www.digitalmeetsculture.net/wp-content/uploads/2014/06/opensource-logo.png"
            alt="OSS Logo"
          ></img>
        </div>
        <br />
        <h3>Then, why should you contribute to Open Source?</h3>
        <ul>
          <li>
            One of the most significant reasons why I believe it is essential to
            contribute to open source projects is the opportunity to learn from
            experts. Through participating in open source projects, you have the
            chance to interact with the most experienced open source developers,
            allowing me to learn from their wealth of experience. Additionally,
            you can receive valuable feedback from these experts, which can help
            me enhance my programming skills and gain a deeper understanding of
            the code.
          </li>
          <br />
          <li>
            Contributing to open source projects can provide you with the
            opportunity to create code that is seen and used by a broad
            community, providing you with instant credibility. Moreover, by
            contributing to open source projects, you can demonstrate your
            ability to work in a team and your aptitude for solving problems.
          </li>
          <br />

          <li>
            Another important benefit of contributing to open source projects is
            the chance to improve the community at large. By contributing to
            open source projects, you can help address problems that impact the
            broader community and enhance software security and usability.
            Additionally, contributing to open source projects can enable you to
            take part in meaningful projects that can make a positive impact on
            the world.
          </li>
          <br />

          <li>
            Finally, contributing to open source projects is an excellent way to
            network in the tech industry. Through working on an open source
            project, you can connect with other developers, leading to the
            establishment of valuable professional relationships. These
            relationships may be beneficial in the future, providing
            opportunities for work, mentorship, and collaboration on future
            projects.
          </li>
          <br />
        </ul>
        <p>
          In summary, contributing to open source projects is an outstanding way
          for programmers to learn and connect with other developers, establish
          a portfolio, and enhance the community. Open source software has
          become the foundation for many of the tools used in daily life.
          Therefore, contributing to open source projects is a way to give back
          to the community and improve software for everyone.
        </p>
        <h3>What I have been working on</h3>
        <p>
          Now that we've covered more about open source, I'd like to share what
          I've been working on this week. I spent most of the week doing
          research and working with Java and Python as my main and second. To
          gain a deeper understanding of the projects I'll be working on, I
          spent a lot of time cloning and running them locally instead of just
          reading the documentation (although I did that too) and of course
          "playing" with them changing some scripts and even trying to change
          some functions. All of these projects are hosted on GitHub, a powerful
          web-based platform for version control and collaborative software
          development.
        </p>
        <p>
          To start off, I picked Intenet Library -{" "}
          <a
            href="https://github.com/internetarchive/openlibrary"
            target="_blank"
            rel="noreferrer"
          >
            Open Library
          </a>{" "}
          to start contributing for open source, this project has some meaning
          to me since I've used other services that Internet Library provides,
          such as{" "}
          <a href="https://archive.org/web/" target="_blank" rel="noreferrer">
            Wayback Machine
          </a>{" "}
          which is a web that lets you see how internet used to look when it
          started.
        </p>
        <p>
          If you're interested in learning more about my contribution to the
          Internet Archive's Open Library project, I invite you to check out the
          technical log of my work. This log provides a detailed account of the
          specific changes I made to the project, as well as the thought process
          behind them. Although it is a small contribution I believe that
          sharing our work and knowledge is an essential part of the open source
          community, and I hope that by publishing this log, I can help others
          learn from my experience and continue to improve this incredible
          project.
        </p>
        <div className="tec-log-buttons">
          <Link className="tec-log-btn" to="/TecLog-4" onClick={toTopHandler}>
            To Technical Log
          </Link>
        </div>
        <p>
          Now that I have gained some understanding of how these projects' code
          works, I am excited to share with you the ones that have caught my
          attention the most so far:
        </p>
        <p>For Java:</p>
        <ul>
          <li>
            <a
              href="https://github.com/eclipse-vertx/vert.x"
              target="_blank"
              rel="noreferrer"
            >
              Vertx
            </a>
          </li>
          <li>
            <a
              href="https://github.com/spring-projects/spring-framework/blob/main/CONTRIBUTING.md"
              target="_blank"
              rel="noreferrer"
            >
              Spring Boot Framework
            </a>
          </li>
          <li>
            <a
              href="https://github.com/quarkusio/quarkus"
              target="_blank"
              rel="noreferrer"
            >
              Quarkus
            </a>
          </li>
        </ul>
        <p>For Python:</p>
        <ul>
          <li>
            <a
              href="https://github.com/internetarchive/openlibrary"
              target="_blank"
              rel="noreferrer"
            >
              Open Library
            </a>
          </li>
          <li>
            <a
              href="https://github.com/tensorflow/tensorflow"
              target="_blank"
              rel="noreferrer"
            >
              TensorFlow
            </a>
          </li>
          <li>
            <a
              href="https://github.com/opencv/opencv"
              target="_blank"
              rel="noreferrer"
            >
              OpenCV
            </a>
          </li>
        </ul>
        <br />
        <p>
          I'm still in the process of contacting the repository
          owners/contributors to start working with them. Most of them have been
          welcoming but these options might change once I pick two of them (one
          for java and one for python)
        </p>
        <h3>Some links you can use to dive deeper into the subject</h3>
        <p>
          Before I wrap up this entry, I wanted to share some useful resources
          that can help anyone get started with open source development, should
          they be interested. From online courses and tutorials to
          community-driven platforms that facilitate collaboration, there are
          many ways to dive into the world of open source. Below, I've compiled
          a list of links to some of the most helpful resources I've come across
          in my own journey, which I hope will be of use to anyone looking to
          get involved.
        </p>
        <ul>
          <li>
            <a
              href="https://opensource.guide/how-to-contribute/"
              target="_blank"
              rel="noreferrer"
            >
              How to contribute to open source?
            </a>
          </li>
          <li>
            <a
              href="https://egghead.io/lessons/javascript-introduction-to-github"
              target="_blank"
              rel="noreferrer"
            >
              Technical guide to contribute to open source (videos)
            </a>
          </li>
        </ul>
        <br />
      </>
    ),
  },
  {
    link: "/Week-21",
    title: "Week 21: Open source research",
    date: "Feb 20, 2023",
    content: (
      <>
        <p>
          This week was exciting but tough. I tried searching for issues on
          every project I listed in the last entry, and to be fair, it was
          harder than I thought. There's a part where you need to install and
          test the project locally, which is not a big deal, but getting used to
          using it and understanding how it works can be challenging in the
          beginning but not impossible.
        </p>

        <p>
          I worked for the first time with Docker in several projects, which was
          really fruitful for me as I always wanted to have hands-on experience
          with this platform. However, I encountered another problem while
          installing all of these... I knew how most of the projects worked and
          tried them successfully, but most of the projects took time for me to
          get a response from the maintainers on every issue I commented on.
          Even though there were several projects with a big user base, it was
          difficult to have follow-up with your messages.
        </p>

        <p>
          That's why I opted to try with small to medium projects and focus only
          on projects that had information tags, that had well-documented
          issues, and of course, ones that had constant activity from the
          maintainers. So to summarize everything I learned this week could be:
        </p>
        <ul>
          <li>
            When working in open source, there are several potential problems
            that one can encounter, despite the many benefits of collaborating
            with a community of like-minded individuals. The first problem is
            the issue of communication. Due to the decentralized nature of open
            source development, it can be difficult to keep track of project
            updates, and communication between contributors and maintainers may
            be slow or inconsistent. This can lead to misunderstandings and
            delays in the development process.
          </li>
          <br />
          <li>
            Another problem is the difficulty of managing different codebases.
            When working on an open source project, contributors may need to
            familiarize themselves with different codebases and development
            environments, which can be challenging and time-consuming.
            Additionally, there may be diferent style in the code and standards,
            which can lead to confusion and conflicts when merging code.
          </li>
          <br />
          <li>
            In addition, contributors may encounter problems related to
            licensing and intellectual property. Open source licenses can be
            complicated, making it challenging to know how to use and share open
            source code while following the rules. It's also important for
            contributors to be mindful of any potential issues with using open
            source code in their own projects that could violate intellectual
            property laws.
          </li>
        </ul>
        <p>
          Despite these potential problems, working in open source can be a
          rewarding experience for contributors who are willing to invest the
          time and effort to navigate these challenges. By collaborating with a
          diverse group of developers, contributors like myself, can gain
          valuable experience and knowledge that can help them improve their
          skills and contribute to the open source community.
        </p>
      </>
    ),
  },
  {
    link: "/Week-22",
    title: "Week 22: Open source: issues",
    date: "Feb 20, 2023",
    content: (
      <>
        <p>
          During this week, we became a little bit more technical as I already
          started working with actual issues. To start off, I picked a project
          where the maintainers and community are overall really welcoming and
          super active. They even own a Slack channel! Said project is:{" "}
          <a
            href="https://github.com/mindsdb/mindsdb"
            target="_blank"
            rel="noreferrer"
          >
            MindsDB
          </a>
          .
        </p>
        <p>
          As a mechatronics student, I was always passionate about neural
          networks, machine learning, and computer vision with Python and C++,
          so that's why I picked this project. The whole code is written in
          Python, and they accept constant integrations where you can work.
        </p>
        <p>
          I have learned a lot, and although I haven't touched or worked on any
          machine learning code related, making an integration with HyperSQL has
          given me some interesting insights on how we can use this amazing tool
          to make predictions.
        </p>
        <p>
          Machine learning algorithms can be used to analyze data within a SQL
          database, which can provide valuable insights and improve the
          efficiency of data processing.
        </p>
        <p>
          I've seen that one of the benefits of using machine learning in SQL
          databases is the ability to automate tasks that would otherwise
          require manual intervention. For example, machine learning algorithms
          can be used to automate data cleaning and normalization, reducing the
          workload of data analysts and allowing them to focus on more complex
          tasks. Additionally, machine learning can be used to identify patterns
          and trends in data that may not be immediately apparent, which can
          lead to new insights and better decision-making.
        </p>
        <p>
          Something else that I noticed when working and playing with the actual
          GUI of the project is that using machine learning in SQL databases has
          the ability to improve predictive modeling. By analyzing historical
          data and identifying patterns, machine learning algorithms can be used
          to make accurate predictions about future events. Although there are
          not many examples within the project with that much data for accurate
          results.
        </p>
        <p>
          Aside from this project, I've been navigating through more Java
          projects, although I still need an issue, I'm pretty sure this week
          will be challenging but rewarding, delivering the pull requests and
          technical logs that I was requested to work on.
        </p>
      </>
    ),
  },
];
