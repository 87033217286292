import React from "react";
import { Link } from "react-router-dom";
import TypeWriter from "../../app1/components/UI/TypeWriter/TypeWriter";

import { entries } from "../assets/entries/entries";
import { teclogs } from "../assets/teclogs/teclogs";
import "./WorkList.css";

function WorkList({ entry, teclog }) {
  const toTopHandler = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="works-scrollable">
      <h1 className="works-title">
        <TypeWriter
          speed={80}
          delay1={10000}
          delay2={300}
          text={[
            entry ? "Weekly entries" : teclog && "Technical Logs",
            "Apprentice ++",
          ]}
          emoji={["🧠", "📕", "📚", "📂"]}
          classes="typewriter"
        />
      </h1>
      {entry && (
        <>
          <ul>
            {entries?.map((entry, i) => (
              <li>
                <Link to={`/${entry.link.substring(1)}`} key={i}>
                  <div
                    className="work-option"
                    onClick={() => {
                      toTopHandler();
                    }}
                  >
                    {entry.title}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}

      {teclog && (
        <>
          {teclogs?.map((teclog, i) => (
            <Link to={`/${teclog.link.substring(1)}`} key={i}>
              <div
                className="work-option"
                onClick={() => {
                  toTopHandler();
                }}
              >
                {teclog.title}
              </div>
            </Link>
          ))}
        </>
      )}
    </div>
  );
}

export default WorkList;
