import { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";

import "./LoadModal.css";

const portalElement = document.querySelector("#overlays");
const LoadModal = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("load", () => {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    });
  }, []);

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Transition in={loading} timeout={3000} mountOnEnter unmountOnExit>
          {(state) => (
            <div
              className={`backdrop-loading${
                state === "exiting" ? "-exit" : ""
              }`}
            >
              <svg
                viewBox="0 0 130 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Atayde2">
                  <path
                    id="DE2"
                    d="M109.955 39.0605C110.879 36.4395 112.2 34.334 113.919 32.7441C114.177 32.4863 114.177 32.1963 113.919 31.874C113.231 31.4014 112.2 30.5742 110.825 29.3926C109.407 28.082 108.859 26.5352 109.182 24.752C109.504 23.6348 110.116 22.5068 111.019 21.3682C110.224 21.8408 109.515 22.2598 108.892 22.625C108.226 23.248 107.388 23.6025 106.378 23.6885C104.294 23.8818 102.425 23.1406 100.771 21.4648C99.8037 19.9824 100.126 19.0371 101.737 18.6289C107.001 15.4707 112.641 12.8604 118.656 10.7979C118.678 10.0674 119.193 9.7666 120.203 9.89551C123.211 10.2822 125.37 11.9688 126.681 14.9551C127.024 17.2969 126.315 18.4248 124.554 18.3389C123.78 18.3604 122.878 17.9736 121.847 17.1787C118.323 19.8643 116.229 22.1523 115.562 24.043C115.176 25.2031 115.724 26.2666 117.206 27.2334C120.708 28.9307 122.771 30.3271 123.394 31.4229C124.124 32.4326 124.253 33.6357 123.78 35.0322C123.351 35.8701 122.223 36.1709 120.396 35.9346C119.021 35.6338 117.689 35.6445 116.4 35.9668C114.553 36.5254 112.404 37.5566 109.955 39.0605ZM122.427 22.4316C123.093 22.7324 123.544 23.2051 123.78 23.8496C123.931 24.4941 123.716 24.8594 123.136 24.9453C122.685 24.9883 122.212 24.7627 121.718 24.2686C121.353 23.9248 120.977 23.5273 120.59 23.0762C120.268 22.3457 120.407 21.9482 121.009 21.8838C121.524 21.9697 121.997 22.1523 122.427 22.4316ZM122.813 20.0469C123.501 20.1973 124.21 20.5518 124.94 21.1104C125.392 21.54 125.553 21.9697 125.424 22.3994C125.316 22.8076 125.005 22.9902 124.489 22.9473C124.081 22.8613 123.694 22.6465 123.329 22.3027C122.964 21.959 122.674 21.7012 122.459 21.5293C122.266 21.3359 122.051 21.0566 121.814 20.6914C121.793 20.2188 122.126 20.0039 122.813 20.0469Z"
                    fill="black"
                  />
                  <path
                    id="Y2"
                    d="M83.2393 38.7705C80.9619 38.8564 79.4795 37.5996 78.792 35V26.46C77.0518 28.6514 75.8057 30.1553 75.0537 30.9717C74.3018 31.7666 73.2598 32.2178 71.9277 32.3252C70.875 32.3252 70.0156 32.0244 69.3496 31.4229C68.6836 30.8213 67.9639 30.2627 67.1904 29.7471C68.0068 28.458 69.0918 27.1367 70.4453 25.7832C71.8203 24.4297 73.3672 22.9043 75.0859 21.207C76.8047 19.4883 78.835 17.6729 81.1768 15.7607C83.54 13.8271 86.1289 12.0977 88.9434 10.5723C89.2441 9.90625 89.7275 9.72363 90.3936 10.0244C90.9521 10.1963 91.6074 10.5937 92.3594 11.2168C93.1113 11.8398 93.5625 12.8496 93.7129 14.2461C93.8633 15.6211 93.6592 16.7598 93.1006 17.6621C92.4346 18.1348 91.6611 18.1562 90.7803 17.7266C88.3525 18.8652 86.3545 20.2725 84.7861 21.9482C85.4307 23.2373 85.667 24.4619 85.4951 25.6221L85.4629 33.4209C85.6562 36.9014 84.915 38.6846 83.2393 38.7705Z"
                    fill="black"
                  />
                  <path
                    id="TA2"
                    d="M35.3838 35.3867C38.7998 34.377 41.5498 32.6152 43.6338 30.1016C44.3643 29.3496 45.3848 28.2324 46.6953 26.75C46.0723 26.5781 45.4707 26.3848 44.8906 26.1699C44.332 25.9551 43.6445 25.8584 42.8281 25.8799C42.0332 26.8896 41.002 27.4697 39.7344 27.6201C38.3594 27.5557 37.457 27.0938 37.0273 26.2344C36.7695 25.418 36.1572 24.6982 35.1904 24.0752C35.9209 23.5596 36.8018 22.5176 37.833 20.9492C38.8857 19.3594 39.9385 17.791 40.9912 16.2441C41.2705 15.7285 41.335 15.0518 41.1846 14.2139C40.7119 12.2803 40.7656 10.9912 41.3457 10.3467C41.9473 9.68066 42.9893 9.66992 44.4717 10.3145C46.2119 11.4531 47.1787 12.8389 47.3721 14.4717C49.6064 13.1396 51.7979 12.1191 53.9463 11.4102C54.1611 10.4863 54.6768 9.9707 55.4932 9.86328C56.8037 9.92773 58.1572 10.8945 59.5537 12.7637C60.9717 14.6113 61.7129 16.7812 61.7773 19.2734C61.8633 20.8848 61.627 22.0342 61.0684 22.7217C60.5312 23.3877 59.7686 23.7744 58.7803 23.8818C56.8467 25.1709 53.3984 28.7588 48.4355 34.6455C45.9434 37.6533 43.752 39.0713 41.8613 38.8994C39.9707 38.749 38.7461 38.3193 38.1875 37.6104C37.6504 36.9229 36.7158 36.1816 35.3838 35.3867ZM56.2666 18.3066C56.2881 17.7051 56.1592 17.2002 55.8799 16.792C52.4209 18.0166 49.1016 19.5312 45.9219 21.3359C45.7285 21.7012 45.5352 21.9912 45.3418 22.2061C47.6406 22.3994 49.0586 22.8076 49.5957 23.4307C51.7871 21.5186 54.0107 19.8105 56.2666 18.3066Z"
                    fill="black"
                  />
                  <path
                    id="A1"
                    d="M6.28418 37.707C5.31738 37.1914 4.30762 36.7617 3.25488 36.418C4.41504 35.7305 5.48926 34.7637 6.47754 33.5176C9.46387 30.0586 11.6875 26.4277 13.1484 22.625C13.1484 21.3359 13.2451 20.4336 13.4385 19.918C12.8799 20.1758 12.2354 20.4658 11.5049 20.7881C10.8604 21.1963 9.80762 21.3574 8.34668 21.2715C6.90723 21.1855 5.47852 20.3477 4.06055 18.7578C3.26562 17.7266 3.05078 16.9746 3.41602 16.502C3.80273 16.0078 4.46875 15.7607 5.41406 15.7607C13.5352 12.8818 19.2715 11.1416 22.623 10.54C23.2246 9.93848 23.9336 9.7666 24.75 10.0244C25.8887 10.5186 26.8232 11.1094 27.5537 11.7969C29.3799 13.5801 30.0566 16.0293 29.584 19.1445C29.1328 21.25 28.123 22.8936 26.5547 24.0752C25.0078 25.2568 23.5898 25.6436 22.3008 25.2354C21.0117 24.8271 19.9268 24.4727 19.0459 24.1719C19.0244 25.6113 18.6699 26.7393 17.9824 27.5557C15.5117 32.7549 13.8359 35.9561 12.9551 37.1592C12.0957 38.3623 11.0752 38.9639 9.89355 38.9639C8.71191 38.9854 7.50879 38.5664 6.28418 37.707ZM18.9492 22.335C20.4531 21.583 22.0322 19.6279 23.6865 16.4697C20.958 17.1572 18.7344 17.9199 17.0156 18.7578C17.7891 19.3379 18.4336 20.5303 18.9492 22.335Z"
                    fill="black"
                  />
                </g>
              </svg>
            </div>
          )}
        </Transition>,
        portalElement
      )}
    </Fragment>
  );
};

export default LoadModal;
